<template>
    <div>
        <h1>登录成功后返回到页面</h1>
        <!--<el-dialog title="手机授权登录" :visible.sync="dialogFormVisible">-->
            <!--<el-form :model="form"  label-width="100px">-->
                <!--<el-form-item label="手机号">-->
                    <!--<el-input v-model="form.phone" clearable></el-input>-->
                <!--</el-form-item>-->

                <!--<el-form-item>-->
                    <!--为了您的更好的体验，请绑定手机号进行授权登录-->
                <!--</el-form-item>-->
            <!--</el-form>-->
            <!--<div slot="footer" class="dialog-footer">-->
                <!--<el-button @click="handleCancel">取 消</el-button>-->
                <!--<el-button type="primary" @click="handleSubmit">确 定</el-button>-->
            <!--</div>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>
  export default {
    data(){
      return{
        dialogFormVisible: true,
        form:{
          phone: ''
        }
      }
    },
    created() {
     // if(sessionStorage.getItem("userSession") && sessionStorage.getItem("userSession") != ''){
     //   this.dialogFormVisible = false;
     //   this.$router.push({ path: '/vipCarInfo' })
     // }else{
     //   // this.getUserInfo()
     // }
      if(this.getQueryString('unionid')){
        sessionStorage.setItem("userSession", this.getQueryString('unionid'))
        this.$router.push({ path: '/vipCarInfo' })
      }
    },
    methods:{
      getQueryString (name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        let search = window.location.href.split('?')[1];
        if (search) {
          let r = search.match(reg);
          if (r !== null) {
            return decodeURIComponent(r[2]);
          }
        }
        return ''
      },
      // getUserInfo().then(res=>{
      //
      // })
      handSubmit(){
        this.dialogFormVisible = false
      },

      handleCancel(){
        this.dialogFormVisible = false;
        this.$router.push({ path: '/' })
      }
    }

  };
</script>

<style scoped>

</style>
